<script setup lang="ts">
import { Field, defineRule, useForm } from 'vee-validate';
import { required as reqRule, min as minRule, max as maxRule } from '@vee-validate/rules';
import { defineProps, withDefaults, computed } from 'vue';

// Define custom rules
defineRule('required', reqRule);
defineRule('min', minRule);
defineRule('max', maxRule);

// Props definition
interface Props {
  counter?: number | string;
  min?: number;
  max?: number;
  required?: boolean;
  hint?: string;
  label?: string;
  placeholder?: string;
}

const props = withDefaults(defineProps<Props>(), {
  counter: 0,
  min: 0,
  max: 0,
  required: false,
  hint: '',
  label: '',
  placeholder: '',
});

// Validation rules
const validationRules = computed(() => {
  const rules: string[] = [];
  if (props.required) {
    rules.push('required');
  }
  if (props.min > 0) {
    rules.push(`min:${props.min}`);
  } else {
    rules.push('min:5'); // Default minimum
  }
  if (props.max > 0) {
    rules.push(`max:${props.max}`);
  }
  return rules.join('|');
});


const { handleSubmit } = useForm();

function submitForm(values: any) {
  console.log('Form Submitted with:', values);
}

function getLabelName() {
  const labelRequired = props.required && props.label !== '' ? ' *' : '';
  return props.label + labelRequired;
}

</script>

<template>
  <div class="baseTextArea">
    <form @submit.prevent="submitForm">
      <Field
        name="input"
        :rules="validationRules"
        v-slot="{ field, errors }"
      >
        <v-textarea
          v-bind="field"
          outlined
          :label="getLabelName()"
          :error-messages="errors"
          :counter="props.counter"
        />
      </Field>
    </form>
  </div>
</template>
